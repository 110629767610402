import React, { useEffect, useRef } from 'react';

interface LogoCarouselProps {
  logos: string[];
}

const LogoCarousel: React.FC<LogoCarouselProps> = ({ logos }) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const node = carouselRef.current;
    if (node) {
      node.style.animationPlayState = 'running';
    }
    return () => {
      if (node) {
        node.style.animationPlayState = 'paused';
      }
    };
  }, []);

  const multipleLogos = [...logos, ...logos, ...logos];

  return (
    <div className="carousel-container">
      <div ref={carouselRef} className="carousel">
        {multipleLogos.map((logo, index) => (
          <div key={index} className="carousel-item desktop">
            <img src={logo} alt={`Company Logo ${index}`} />
          </div>
        ))}
        {multipleLogos.map((logo, index) => (
          <div key={index} className="carousel-item mobile">
            <img src={logo} alt={`Company Logo ${index}`} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoCarousel;
